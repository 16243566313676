<template>
    <section v-show="id_pedido">
        <div v-if="pedido.id_pedido != null" class="border-bottom p-2 text-general">
            <div class="row mx-0 align-items-center my-2">
                <img class="border rounded-circle bg-whitesmoke" :src="pedido.datos_pedido.avatar ? pedido.datos_pedido.avatar : '/img/no-imagen/sin_user.png'" width="50" height="50" alt="" />
                <p class="mx-2 f-500 text-general f-14">{{ pedido.datos_pedido.nombre }}</p>
                <div class="br-20 f-15 px-1 text-general d-middle col-auto px-0 ml-auto">
                    <i class="f-18 icon-pedidos" />
                    <span class="mx-2 f-13">{{ pedido.datos_pedido.cant_pedidos }}</span>
                </div>
            </div>
            <div class="row mx-0 my-2 align-items-center">
                <el-tooltip placement="bottom" content="Dirección" effect="light">
                    <i class="icon-location text-general f-20 mr-2" />
                </el-tooltip>
                <p class="col f-13">{{ pedido.datos_pedido.direccion }}</p>
            </div>
            <div class="row mx-0 my-2 align-items-center">
                <el-tooltip placement="bottom" content="Cobertura" effect="light">
                    <i class="icon-flecha-orden text-general f-20 mr-2" />
                </el-tooltip>
                <p class="col f-13">{{ pedido.distancia }} Mts Aprox.</p>
            </div>
            <div class="row mx-0 my-2 align-items-center">
                <i class="icon-phone text-general f-20 mr-2" />
                <p class="col f-13">{{ pedido.datos_pedido.telefono || 'No registra' }}</p>
            </div>
            <div class="row mx-0 my-2 align-items-center">
                <i class="icon-identification f-16 text-general mr-3" />
                <p class="col f-15 text-general">{{ pedido.datos_pedido.identificacion || 'No registra' }}</p>
            </div>
            <div class="row mx-0 my-2 align-items-center">
                <el-tooltip placement="bottom" content="Fecha Entrega" effect="light">
                    <i class="icon-calendar-clock text-general f-20 mr-2" />
                </el-tooltip>
                <p class="col f-13">{{ pedido.fecha_entrega | helper-fecha('ddd DD MMMM hh:mm a') }}</p>
            </div>
        </div>
        <div class="border-bottom p-2">
            <div class="row mx-0 align-items-center">
                <div class="col-auto pr-2 pl-0">
                    <i class="icon-storefront f-20 text-general" />
                </div>
                <div class="col pl-0 f-17 text-general f-500">
                    Atiende
                </div>
            </div>
            <div class="d-flex mx-0 my-2">
                <div class="col-auto px-3" />
                <div class="col pl-1">
                    <div class="row mx-0 align-items-center">
                        <img class="border br-8 bg-whitesmoke" :src="cliente.logo_mini" width="40" height="40" />
                        <div class="col pl-2 pr-0">
                            <p class="f-13 text-general f-500">
                                {{ cliente.nombre }}
                            </p>
                        </div>
                        <div class="br-20 f-13 px-1 text-general d-middle col-auto px-0">
                            <el-tooltip placement="bottom" :content="`Pedidos Realizados por este ${$config.vendedor}`" effect="light">
                                <i class="f-18 icon-pedidos" />
                            </el-tooltip>
                            <span class="mx-2 f-13">{{ cliente.cant_pedidos }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 align-items-center text-general f-13">
                <el-tooltip placement="top" content="Fecha de Entrega" effect="light">
                    <i class="icon-calendar-clock text-general f-20 mr-2" />
                </el-tooltip>
                <p class="col pl-0 f-13">{{ pedido.fecha_entrega | helper-fecha('ddd DD MMM hh:mm a') }}</p>
            </div>
            <div class="row mx-0 text-general f-13">
                <div class="col-auto pl-0 pr-2">
                    <el-tooltip placement="top" content="Dirección" effect="light">
                        <i class="icon-location text-general f-20" />
                    </el-tooltip>
                </div>
                <div class="col pl-0">
                    <p class="text-general mt-1 f-500 mb-2">Dirección de Domicilio</p>
                    <p class="f-13">{{ cliente.direccion }}</p>
                </div>
            </div>
            <div class="row mx-0 my-2 f-13 align-items-center text-general">
                <div class="col-auto pl-0 pr-2">
                    <i class="icon-phone text-general f-20" />
                </div>
                <p class="col pl-0 f-13">{{ cliente.telefono_celular }}</p>
            </div>
            <div class="row mx-0 f-13 align-items-center text-general mb-2">
                <el-tooltip placement="top" content="Valor Domicilio" effect="light">
                    <i class="icon-truck-fast-outline text-general f-20 mr-2" />
                </el-tooltip>
                <p class="col f-13 pl-1">{{ convertMoney(pedido.valor_envio, pedido.idm_moneda) }}</p>
            </div>
            <div class="row mx-0 f-13 mb-2">
                <div class="col-auto pl-0 pr-2">
                    <i class="icon-creditos f-17 text-general" />
                </div>
                <div class="col pl-1">
                    <span class="text-general ">Créditos</span>
                </div>
            </div>
            <div class="row mx-0 f-15">
                <div class="col-auto px-3" />
                <div class="col pl-1">
                    <el-tooltip placement="top" content="Deuda Vigente" effect="light">
                        <i class="icon-clock text-general f-18" />
                    </el-tooltip>
                    <span class="f-14 text-general mr-2">{{ convertMoney(pedido.saldo_vigente,pedido.idm_moneda) }}</span>

                    <el-tooltip placement="top" content="Deuda Vencida" effect="light">
                        <i class="icon-attention-alt text-danger f-18" />
                    </el-tooltip>
                    <span class="f-14 text-general">{{ convertMoney(pedido.saldo_vencido,pedido.idm_moneda) }}</span>
                </div>
            </div>
        </div>
        <!-- Picking & Packing -->
        <div class="border-bottom text-general p-2">
            <div class="row mx-0 my-2 align-items-center">
                <div class="col-auto pl-0 pr-2">
                    <i class="icon-shopping-outline f-22" />
                </div>
                <p class="col f-600 f-15 pl-0 text-general">Picking & Packing</p>
            </div>
            <div class="row mx-0 mt-1 text-general align-items-center">
                <div class="col-auto px-3 mr-1" />
                <div class="col px-0 d-flex">
                    <div class="bg-general2 border mr-2 br-5 d-middle-center" style="width:40px;height:40px;">
                        <img v-if="empacador.foto != null" class="border br-5 bg-whitesmoke" :src="empacador.foto" width="100%" height="100%" />
                        <i v-else class="icon-shopping-outline f-25 py-2 px-1 text-white" />
                    </div>
                    <div class="col px-0">
                        <p class="text-general2 f-14 f-500">Empacador</p>
                        <p class="f-14 f-500 cr-pointer">{{ empacador.nombre || 'No Registra' }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="pedido.operacion_logistica_cedi != 2" class="border-bottom text-general p-2">
            <div class="row mx-0 my-2 align-items-center">
                <div class="col-auto pl-0 pr-2">
                    <i class="icon-moped f-22" />
                </div>
                <p class="col f-600 f-16 pl-0 text-general">Confirmación de Entrega</p>
            </div>
            <div class="row mx-0 mt-1 text-general align-items-center">
                <div class="col-auto px-3 mr-1" />
                <div v-if="pedido.id_user_delivery === null" class="col px-0 d-flex">
                    <div class="bg-general2 border mr-2 br-5 d-middle-center" style="width:40px;height:40px;">
                        <i class="icon-moped f-25 py-2 px-1 text-white" />
                    </div>
                    <div class="col px-0">
                        <p class="text-general2 f-14 f-500">Delivery</p>
                        <p class="f-14">No Registra</p>
                    </div>
                </div>
                <div v-else class="col-12 f-13">
                    <div class="col d-flex">
                        <div class="bg-general2 border mr-2 br-5 d-middle-center" style="width:40px;height:40px;">
                            <img class="border br-5 bg-whitesmoke" :src="delivery.foto" width="100%" height="100%" />
                        </div>
                        <div class="col px-0">
                            <p class="text-general2 f-14 f-500">Delivery</p>
                            <p>{{ delivery.nombre }}</p>
                        </div>
                    </div>
                    <div v-if="pedido.entrega">
                        <div class="col mt-2">
                            <p>Delivery llegó y confirmo</p>
                            <p>{{ entrega.created_at | helper-fecha('DD-MMM hh:mm a') }}</p>
                        </div>
                        <div class="col pt-2">
                            <p>"Cliente" confirmo entrega</p>
                            <p>{{ entrega.momento_cliente | helper-fecha('DD-MMM hh:mm a') }}</p>
                        </div>
                        <div class="col pt-2">
                            <p>Delivery recibio</p>
                            <p>{{ entrega.momento_delivery | helper-fecha('DD-MMM hh:mm a') }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="pedido.id_pedido != null" class="row mx-0 mt-3 justify-content-around">
                <p class="col-12 f-14 f-500 text-general my-2 px-0">
                    {{ pedido.cliente_entrega_directa ? `Enviado al ${$config.cliente}` : `Enviado al ${$config.vendedor}` }}
                </p>
            </div>
            <div class="row mx-0 mt-3 mb-2 text-general align-items-center">
                <div v-show="pedido.otra_direccion" class="col px-2 f-13 text-general2">
                    Enviado a una dirección diferente a la registrada.
                </div>
            </div>
        </div>
        <!-- Inicio operador logístico externo -->
        <div v-else class="border-bottom text-general p-2">
            <div class="row mx-0 my-2 align-items-center">
                <div class="col-auto pl-0 pr-2">
                    <i class="icon-moped f-22" />
                </div>
                <p class="col f-600 f-16 pl-0 text-general">Operador logístico externo</p>
            </div>
            <div class="row mx-0 mt-1 ml-4 mr-4 text-general align-items-center">
                <div class="col-12 px-3 mr-1">
                    <div class="mx-0 align-items-center">
                        <p class="f-14">{{ estadoOperador() }}</p>
                    </div>
                </div>
            </div>
            <div v-if="pedido.id_pedido != null" class="row mx-0 mt-2 justify-content-around">
                <p class="col-12 f-14 f-500 text-general my-2 px-0">
                    {{ pedido.cliente_entrega_directa ? `Enviado al ${$config.cliente}` : `Enviado al ${$config.vendedor}` }}
                </p>
            </div>
            <div class="row mx-0 mt-3 mb-2 text-general align-items-center">
                <div v-show="pedido.otra_direccion" class="col px-2 f-13 text-general2">
                    Enviado a una dirección diferente a la registrada.
                </div>
            </div>
        </div>
        <!-- Delivery -->
        <!-- linea de tiempo -->
        <div class="border-bottom py-2">
            <div class="row mx-0 justify-center mb-3">
                <div v-for="(act, index) in historial" :key="index" class="d-middle" style="width:53px">
                    <div class="rounded-circle position-relative" :class="act.activo?'bg-general3':'bg-gris'" style="width:40px;height:40px;padding:1px">
                        <div class="w-100 h-100 rounded-circle d-middle-center bg-white">
                            <i :class="`f-20 ${act.icon} ${act.activo?'text-general':'text-gris'}`" />
                        </div>
                    </div>
                    <div v-if="index != historial.length-1" class="w-25" :class="act.activo?'bg-general3':'bg-gris'" style="height:3px;" />
                </div>
            </div>
            <p class="text-general f-500 f-16 text-center mb-3">
                {{ nombreEstado }}
            </p>
            <template v-if="showHistorial">
                <div v-for="(act, index) in historial" :key="index" class="row mx-0">
                    <div class="col-auto">
                        <div class="rounded-circle position-relative" :class="act.activo?'bg-general3':'bg-gris'" style="width:20px;height:20px;padding:1px">
                            <div class="w-100 h-100 rounded-circle d-middle-center bg-white">
                                <i v-if="act.activo" :class="`f-20 icon-ok-circled ${act.activo?'text-general':'text-gris'}`" />
                            </div>
                        </div>
                        <div v-show="index != historial.length-1" class="mx-auto h-100 py-2" :class="act.activo?'bg-general3':'bg-gris'" style="width:2px;" />
                    </div>
                    <div class="col">
                        <div class="row mx-0">
                            <div class="col px-1">
                                <p class="f-14" :class="act.activo?'text-general':'text-gris'">{{ act.content }}</p>
                                <p class="f-14">{{ act.hora | helper-fecha('hh:mm a DD MMM') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <p class="text-general f-500 f-15 text-center mt-2 cr-pointer" @click="showHistorial = !showHistorial">
                <span v-text="showHistorial ? 'Ver menos' : 'Ver más'" />
                <i :class="showHistorial ? 'icon-angle-up' : 'icon-angle-down'" />
            </p>
        </div>
        <!-- linea de tiempo -->
        <div class="border-bottom p-2">
            <div class="row mx-0 align-items-center cr-pointer">
                <div style="width:36px" class="text-center">
                    <i class="icon-pedidos f-20 text-general" />
                </div>
                <div class="col pl-0 my-auto text-general f-17 f-500">
                    Más información
                </div>
            </div>
            <div class="row mx-0 mb-2">
                <div class="col-auto px-3" />
                <div class="col pl-0">
                    <div class="row mx-0 text-general f-15">
                        <p class="col pl-1">
                            No. Del pedido
                        </p>
                        <p class="col text-right">
                            {{ pedido.id }}
                        </p>
                    </div>
                    <div class="row mx-0 text-general f-15">
                        <p class="col pl-1">
                            Fecha Creación
                        </p>
                        <p class="col text-right">
                            {{ pedido.created_at | helper-fecha('DD MMM YYYY') }}
                        </p>
                    </div>
                    <div class="row mx-0">
                        <div class="col pl-1 f-15 text-general">
                            <p class="text-general f-500 mt-3">Instrucciones</p>
                            <p v-html="fun_caracteres(pedido.instrucciones)" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="convenio" class="border-bottom text-general p-2">
            <div class="row mx-0 my-2 align-items-center">
                <div class="col-auto pl-0 pr-2">
                    <i class="icon-convenio f-22" />
                </div>
                <p class="col f-600 f-17 pl-0 text-general">Convenio de nómina</p>
            </div>
            <div class="row mx-0 align-items-center my-2">
                <div class="col-auto px-3 mr-1" />
                <img class="border br-10 bg-whitesmoke obj-cover" :src="convenio.logo" width="50" height="50" alt="" />
                <div class="col f-14 text-general">
                    <p class=" f-500 ">{{ convenio.nombre }}</p>
                    <p>{{ convenio.tipo_pago }}</p>
                    <p> Fecha Liquidación : {{ convenio.fecha_liquidacion | helper-fecha('DD MMMM YYYY') }} </p>
                </div>
            </div>
        </div>
        <div class="p-2 text-general">
            <div class="row mx-0 align-items-center text-general f-500 f-18">
                <div class="d-middle" style="width:36px;">
                    <i class="icon-comision f-14" />
                </div>
                <div class="col pl-0">
                    Detalle del Cobro
                </div>
            </div>
            <div class="row mx-0 f-15">
                <div style="width:36px" />
                <p>Costo de productos ({{ resumen.cantidad_productos }})</p>
                <b class="text-general ml-auto">
                    {{ convertMoney(resumen.val_productos,pedido.idm_moneda) }}
                </b>
            </div>
            <div class="row mx-0 f-15">
                <div style="width:36px" />
                <p>Descuento</p>
                <b class="text-general ml-auto">
                    {{ convertMoney(resumen.val_descuentos,pedido.idm_moneda) }}
                </b>
            </div>
            <div v-if="resumen.id_cupon" class="row mx-0 f-15">
                <div style="width:36px" />
                <div class="d-middle">
                    <i class="icon-cupon pr-1" />
                    <p>{{ resumen.codigo_cupon }}</p>
                </div>
                <b class="text-general ml-auto">
                    {{ convertMoney(resumen.val_cupon, pedido.idm_moneda) }}
                </b>
            </div>
            <div v-show="convenio" class="row mx-0 f-15">
                <div style="width:36px" />
                <p>Descuento Nomina</p>
                <b class="text-general ml-auto">
                    {{ convertMoney(resumen.val_nomina,pedido.idm_moneda) }}
                </b>
            </div>
            <div class="row mx-0 f-15">
                <div style="width:36px" />
                <p>Alianza {{ $config.vendedor }}</p>
                <b class="text-general ml-auto">
                    {{ convertMoney(resumen.val_condicion,pedido.idm_moneda) }}
                </b>
            </div>
            <div v-if="resumen.val_descuento_lista > 0" class="row mx-0 f-15">
                <div style="width:36px" />
                <p>Descuento Lista</p>
                <b class="text-general ml-auto">
                    {{ convertMoney(resumen.val_descuento_lista,pedido.idm_moneda) }}
                </b>
            </div>
            <div class="row mx-0 f-15 f-600 border-top pt-2">
                <div style="width:36px" />
                <p class="text-general">Sub Total</p>
                <p class="text-general ml-auto">{{ convertMoney(resumen.subtotal,pedido.idm_moneda) }}</p>
            </div>
            <div class="row mx-0 f-15">
                <div style="width:36px" />
                <p>Impuestos</p>
                <b class="text-general ml-auto">
                    {{ convertMoney(resumen.val_impuestos,pedido.idm_moneda) }}
                </b>
            </div>
            <div class="row mx-0 f-15">
                <div style="width:36px" />
                <p>Costo de envío</p>
                <b class="text-general ml-auto">
                    {{ convertMoney(resumen.val_domicilios,pedido.idm_moneda) }}
                </b>
            </div>
            <div class="row mx-0 mt-2 f-600 justify-content-between border-top pt-2">
                <div style="width:36px" />
                <p class="text-general">Total a cobrar</p>
                <p class="text-general ml-auto">{{ convertMoney(resumen.val_total,pedido.idm_moneda) }}</p>
            </div>
            <div v-show="resumen.ganancia > 0" class="row mx-0 my-2 ticket br-8 py-1">
                <div class="col pl-1 px-0 text-general f-15">
                    Ganancias {{$config.vendedor}}
                </div>
                <div class="col-auto ml-auto total-value">
                    {{ convertMoney(resumen.ganancia, pedido.idm_moneda) }}
                </div>
            </div>
        </div>
        <div class="text-general p-2 mt-2">
            <div class="row mx-0 align-items-center mb-2">
                <div style="width:36px">
                    <i class="icon-cedis text-general f-20" />
                </div>
                <p class="col text-general f-17 pl-0 f-500">
                    Responsable CEDIS
                </p>
            </div>
            <div class="row m-0 px-2 align-items-center text-general">
                <div class="col-auto px-3" />
                <div class="col pl-0 d-flex">
                    <img class="border br-5 bg-whitesmoke" :src="pedido.foto_responsable ? pedido.foto_responsable : '/img/no-imagen/sin_user.png' " width="45" height="45" />
                    <p class="col f-14 px-2">
                        <span class="f-500">{{ pedido.responsable || 'Sin Asignar' }} </span>
                        <br />
                        Operario responsable
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    data(){
        return{
            ver_mas:false,
            showHistorial: false
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_historial_admin/id_pedido',
            pedido: 'pedidos/pedidos_historial_admin/pedido',
            cliente: 'pedidos/pedidos_historial_admin/cliente',
            delivery: 'pedidos/pedidos_historial_admin/delivery',
            historial: 'pedidos/pedidos_historial_admin/historial',
            resumen: 'pedidos/pedidos_historial_admin/resumen',
            // cuotas: 'pedidos/pedidos_historial_admin/cuotas',
            //pagos: 'pedidos/pedidos_historial_admin/pagos',
            entrega: 'pedidos/pedidos_historial_admin/entrega',
            convenio: 'pedidos/pedidos_historial_admin/convenio',
            empacador: 'pedidos/pedidos_historial_admin/empacador',

        }),
        nombreEstado(){
            if(!this.historial.length) return ''
            let filtered = this.historial.filter(el => el.activo)
            if(!filtered.length) return ''

            return filtered[filtered.length - 1]["content"] ?? '';
        }
    },
    methods: {
        fun_caracteres(cadena){
            if(!cadena) return
            let total = cadena.length
            if(this.ver_mas){
                return this.formatearTextoHtml(cadena.substr(0,total))
            }
            return this.formatearTextoHtml(cadena.substr(0,300))
        },
        estadoOperador(){
            switch (this.pedido.operador_logistico_pedido){
            case 1:
                return "Solicitud enviada, esperando respuesta";
            case 2:
                return "Entrega cancelada";
            case 3:
                return "Solicitud rechazada";
            case 4:
                return "Solicitud aceptada";
            default:
                return ""
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.br-12{ border-radius: 12px;}
.header-credito{ border-radius: 12px 12px 0 0px; height: 50px; }
.borders{ border-left: 1px solid #5D5D5D59; border-right: 1px solid #5D5D5D59; border-bottom: 1px solid #5D5D5D59; border-radius: 0px 0px 12px 12px;}
.text-red{ color: #FF2525; }
.text-green{ color: #28D07B; }
.br-t-12 {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.ticket {
  border: 1px solid #28d07b;
  background-color: #28d07b33;
  .total-value {
    color: #28d07b;
    font-weight: 600;
  }
}
</style>
